@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@300;400;700&display=swap");

body {
  font-family: "Oswald";
}

.active {
  @apply text-red-500;
}

.koza {
}

.fl {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.final {
  background-image: linear-gradient(45deg, #ceff1a, #36c9c6);
  background-size: 400%;
  animation: bg-animation 20s infinite alternate;
}

@keyframes bg-animation {
  0% {
    background-position: left;
  }
  100% {
    background-position: right;
  }
}
.switch {
  position: relative;
  height: 20px;
  width: 50px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  @apply bg-gray-300;
  display: flex;
  align-items: center;
  border-radius: 10%;
}

.darkSlider {
  position: absolute;
  cursor: pointer;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  @apply bg-red-500;
  display: flex;
  align-items: center;
  border-radius: 10%;
}

.darkSlider:before {
  position: absolute;
  content: "";
  height: 11px;
  width: 15px;
  background-color: white;
  margin-left: 4px;
  border-radius: 50%;
}
.slider:before {
  position: absolute;
  content: "";
  height: 11px;
  width: 15px;
  background-color: white;
  margin-left: 4px;
  border-radius: 50%;
}

.switch input[type="checkbox"]:checked + .slider:before {
  transform: translateX(15px);
}

.fl2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
